import FormPosto from "../../../components/formPosto/FormPosto"
import { Container } from "../../../design/Layout"

function EscolhaDoDia() {
  return (
    <Container>
      <FormPosto />
    </Container>
  )
}

export default EscolhaDoDia